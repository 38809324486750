.ruleGroup {
  background-color: rgba(0, 0, 0, 0.05);
  border-color: grey;
}

.queryBuilder,
.queryBuilder > .ruleGroup {
  background-color: transparent;
  border: none;
}

.rule-operators,
.ruleGroup-combinators {
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji,
    Segoe UI Emoji;
  height: 2.25rem;
  -webkit-tap-highlight-color: transparent;
  line-height: calc(2.25rem - 0.125rem);
  resize: none;
  box-sizing: border-box;
  font-size: 0.875rem;
  color: #000;
  display: block;
  text-align: left;
  border: 0.0625rem solid #ced4da;
  background-color: #fff;
  -webkit-transition: border-color 100ms ease;
  transition: border-color 100ms ease;
  min-height: 2.25rem;
  padding-left: calc(2.25rem / 3);
  padding-right: calc(2.25rem / 3);
  border-radius: 0.25rem;
}
.rule-fields,
.rule-value:not(:has(.rule-value-list-item)) {
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji,
    Segoe UI Emoji;
  height: 2.25rem;
  -webkit-tap-highlight-color: transparent;
  line-height: calc(2.25rem - 0.125rem);
  resize: none;
  box-sizing: border-box;
  font-size: 0.875rem;
  width: 100%;
  color: #000;
  display: block;
  text-align: left;
  border: 0.0625rem solid #ced4da;
  background-color: #fff;
  -webkit-transition: border-color 100ms ease;
  transition: border-color 100ms ease;
  min-height: 2.25rem;
  padding-left: calc(2.25rem / 3);
  padding-right: calc(2.25rem / 3);
  border-radius: 0.25rem;
}

.rule-value-list-item {
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji,
    Segoe UI Emoji;
  height: 2.25rem;
  -webkit-tap-highlight-color: transparent;
  line-height: calc(2.25rem - 0.125rem);
  resize: none;
  box-sizing: border-box;
  font-size: 0.875rem;
  width: 100%;
  color: #000;
  display: block;
  text-align: left;
  border: 0.0625rem solid #ced4da;
  background-color: #fff;
  -webkit-transition: border-color 100ms ease;
  transition: border-color 100ms ease;
  min-height: 2.25rem;
  border-radius: 0.25rem;
  padding-left: calc(2.25rem / 3);
  padding-right: calc(2.25rem / 3);
}

.rule-value-list-item:first-child {
  margin-left: -(calc(2.25rem / 3));
}
.rule-value-list-item:last-child {
  margin-right: -(calc(2.25rem / 3));
}

.rule-value:has(.rule-value-list-item) {
  height: 2.25rem;
  -webkit-tap-highlight-color: transparent;
  line-height: calc(2.25rem - 0.125rem);
  resize: none;
  box-sizing: border-box;
  width: 100%;
  display: block;
  min-height: 2.25rem;
  border: 0.0625rem solid transparent;
  padding-left: calc(2.25rem / 3);
  padding-right: calc(2.25rem / 3);
}

.ruleGroup-combinators:focus,
.rule-fields:focus,
.rule-operators:focus,
.rule-value:focus,
.rule-value-list-item:focus {
  outline: none;
  border-color: #fd7e14;
}

.ruleGroup-addRule:disabled,
.ruleGroup-addGroup:disabled,
.rule-remove:disabled,
.ruleGroup-remove:disabled {
  cursor: default;
  display: none;
}

.ruleGroup-combinators:disabled,
.rule-fields:disabled,
.rule-operators:disabled,
.rule-value:disabled,
.rule-value-list-item:disabled {
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  appearance: none;
}

.ruleGroup-addRule,
.ruleGroup-addGroup {
  cursor: pointer;
  border: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji,
    Segoe UI Emoji;
  -webkit-tap-highlight-color: transparent;
  line-height: calc(2.25rem - 0.125rem);
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  appearance: none;
  background-color: transparent;
  text-align: left;
  -webkit-text-decoration: none;
  text-decoration: none;
  box-sizing: border-box;
  width: auto;
  font-weight: 600;
  position: relative;
  line-height: 1;
  font-size: 0.875rem;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  color: #fd7e14;
  -webkit-box-align: center;
  resize: none;
  display: block;
  min-height: 2.25rem;
  padding-left: calc(2.25rem / 3);
  padding-right: calc(2.25rem / 3);
  border-radius: 0.25rem;
}

.ruleGroup-addRule:hover,
.ruleGroup-addGroup:hover {
  background-color: rgba(255, 244, 230, 1);
}

.rule-remove:hover,
.ruleGroup-remove:hover {
  border: 0.0625rem solid #fd7e14;
}

.rule-remove,
.ruleGroup-remove {
  cursor: pointer;
  border: 0.0625rem solid transparent;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji,
    Segoe UI Emoji;
  -webkit-tap-highlight-color: transparent;
  line-height: calc(2.25rem - 0.125rem);
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  appearance: none;
  background-color: #ffcea683;
  text-align: left;
  -webkit-text-decoration: none;
  text-decoration: none;
  box-sizing: border-box;
  width: auto;
  font-weight: 600;
  position: relative;
  line-height: 1;
  font-size: 1rem;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  color: #fd7e14;
  -webkit-box-align: center;
  resize: none;
  display: block;
  min-height: 2.25rem;
  padding-left: calc(2.25rem / 3);
  padding-right: calc(2.25rem / 3);
  border-radius: 0.25rem;
}
